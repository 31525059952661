<template>
  <BaseModal
    :loading="loading"
    :dialog="createDialog"
    v-on:cancel="$emit('cancel')"
    width="1080"
  >
    <template v-slot:title>
      Create Patient
    </template>
    <template v-slot:body>
      <v-row>
        <v-col cols="4" xs="12">
          <v-text-field
            v-model="patientObj.firstName"
            label="First Name"
            :readonly="loading"
            color="secondary"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4" xs="12">
          <v-text-field
            v-model="patientObj.middleName"
            label="Middle Name"
            :readonly="loading"
            color="secondary"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4" xs="12">
          <v-text-field
            v-model="patientObj.lastName"
            label="Last Name"
            :readonly="loading"
            color="secondary"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4" xs="12">
          <BaseBirthDatePicker
            :birthDate="patientObj.dateOfBirth"
            @change="patientObj.dateOfBirth = $event"
          />
        </v-col>
        <v-col cols="4" xs="12">
         <v-text-field
            v-model="patientObj.gender"
            label="Gender"
            :readonly="loading"
            color="secondary"
            outlined
            :counter="5"
            
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row> </v-row>
      <v-divider class="pb-4" />
      <v-row>
        <v-col cols="12">
          <h3>Contact</h3>
        </v-col>
        <v-col cols="6" xs="12">
          <v-text-field
            v-model="patientObj.homePhone"
            label="Home Phone"
            v-mask="phoneMask"
            color="secondary"
            :readonly="loading"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" xs="12">
          <v-text-field
            v-model="patientObj.mobilePhone"
            label="Mobile Phone"
            v-mask="phoneMask"
            color="secondary"
            outlined
            :readonly="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" xs="12">
          <v-text-field
            v-model="patientObj.emergencyContactName"
            label="Emergency Contact Name"
            color="secondary"
            outlined
            :readonly="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="6" xs="12">
          <v-text-field
            v-model="patientObj.emergencyContactPhone"
            label="Emergency Contact Phone"
            v-mask="phoneMask"
            color="secondary"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="pb-4" />
      <v-row>
        <v-col cols="12">
          <h3>Home Address</h3>
        </v-col>
        <v-col cols="6" xs="12">
          <v-text-field
            v-model="patientObj.address1"
            label="Address One"
            color="secondary"
            :readonly="loading"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" xs="12">
          <v-text-field
            v-model="patientObj.address2"
            label="Address Two"
            color="secondary"
            outlined
            :readonly="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="4" xs="12">
          <v-text-field
            v-model="patientObj.city"
            label="City"
            color="secondary"
            outlined
            :readonly="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="4" xs="12">
          <v-text-field
            v-model="patientObj.stateCd"
            label="State"
            :readonly="loading"
            color="secondary"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4" xs="12">
          <v-text-field
            v-model="patientObj.zip"
            label="Zip"
            color="secondary"
            :readonly="loading"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="pb-4" />
      <v-row>
        <v-col xs="12">
          <v-textarea
            v-model="patientObj.notes"
            color="Secondary"
            outlined
            :readonly="loading"
            label="Notes"
            height="400px"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="submit"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :disabled="loading"
        submit
        >submit</v-btn
      >
      <v-btn
        @click="clear"
        color=""
        depressed
        class="v-btn--text error--text"
        :disabled="loading"
        >clear</v-btn
      >
    </template>
  </BaseModal>
</template>

<script>
import services from "@/services";
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    createDialog: {
      type: Boolean,
      default: false,
    },
    patientId: {
      type: Number,
      default: 0,
    },
  },
  name: "session-add",
  data: () => ({
    loading: false,
    show1: false,
    valid: false,
    statusLoader: false,
    phoneMask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ") ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    menu1: false,
        genderSelection: [],
    genderItems: ["Male", "Female", "Other"],
    patientObj: {
      firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "1950-01-01",
        gender: "",
        homePhone: "",
        mobilePhone: "",
        emergencyContactName: "",
        emergencyContactPhone: "",
        address1: "",
        address2: "",
        city: "",
        stateCd: "",
        zip: "",
        notes: "",
    },
    statusList: [],
    dictionary: {
      attributes: {
        email: "E-mail Address",
        // custom attributes
      },
      custom: {
        // name: {
        //   required: () => "Name can not be empty",
        //   max: "The name field may not be greater than 10 characters",
        //   // custom messages
        // },
        // select: {
        //   required: () => "Select field is required",
        // },
      },
    },
  }),
  methods: {
    async submit() {
      this.$validator.validateAll();
      this.loading = true;
        var that = this;

        var patient = this.patientObj;

      await services.patientService
        .createPatient(patient)
        .then(response => {
          if (response) {
            that.$store.commit(
              "snack/set_snack",
              "Patient was successfully created."
            );
            that.$emit("createdPatient", response);
            return that.clear();
          } else {
            return that.$store.commit(
              "snack/set_snack",
              "Failed to create patient. Please try again."
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(() => {
          return that.$store.commit(
            "snack/set_snack",
            "Failed to create patient. Please try again."
          );
        })
        .finally(() => (that.loading = false));
    },
    clear() {
      this.patientObj = {
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "1950-01-01",
        gender: "",
        homePhone: "",
        mobilePhone: "",
        emergencyContactName: "",
        emergencyContactPhone: "",
        address1: "",
        address2: "",
        city: "",
        stateCd: "",
        zip: "",
        notes: "",
      };
      this.$validator.reset();
    },
  },
  mounted: function() {
    this.$validator.localize("en", this.dictionary);
    // this.clear();
  },
};
</script>
