<template>
  <section>
    <v-toolbar style="border-bottom: 1px solid #c0c0c0">
        <v-toolbar-title>
          Patients
        </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
          <v-col >
          <v-text-field
            v-model="searchText"
            label="Search"
            single-line
            append-icon="mdi-magnify"
            outlined
            dense
            hide-details
            full-width
          ></v-text-field>
          </v-col>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-col>
          <v-select
            v-model="selectedStatus"
            :multiple="true"
            :loading="statusLoader"
            :items="statusList"
            label="Patient Status"
            color="secondary"
            item-text="description"
            item-value="lookSessionStatusId"
            hide-details
            outlined
            clearable
            single-line
            full-width
            dense
            :return-object="true"
          >
            <template v-slot:selection="{ attrs, item, index, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                :color="getAlertColor(item)"
                v-if="index < 1"
              >
                <span>{{ item.description }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text caption"
                >(+{{ selectedStatus.length - 1 }} others)</span
              >
            </template>
          </v-select>
          </v-col>
        </v-toolbar-items>
        
          <v-toolbar-items>
            <v-col>
          <v-btn block color="primary" @click="createDialog = true" v-if="isAdminUser">
            Create Patient
          </v-btn>
            </v-col>
        </v-toolbar-items>
      </v-toolbar>
    <v-card flat>
      
      <v-divider />
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items-per-page="30"
          :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [5, 10, 30, -1]
            }"
          single-select
          @click:row="rowClick"
          :items="items"
            
          :loading="loading"
          :search="searchText"
          :options.sync="options"
          :server-items-length="totalCount"
          :multi-sort="true"
        >
          <template v-slot:item.lastName="{ item }">
            <span>{{ item.firstName }} {{ item.lastName }}</span>
          </template>
          <template v-slot:item.patientId="{ item }">
            <v-chip pill>{{ item.patientId }}</v-chip>
          </template>
          <template v-slot:item.setupDate="props">
            {{ formatDate(props.item.setupDate) }}
          </template>
          <template v-slot:item.disconnectDate="props">
            {{ formatDate(props.item.disconnectDate) }}
          </template>
          <template v-slot:item.sessionStatusId="{ item }">
            <v-chip :color="getAlertColor(item.patientStatus)" label>
              <span v-if="item.patientStatus && item.patientStatus.description">
                {{ item.patientStatus.description }}
              </span>
              <span v-else>
                - -
              </span>
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <createPatient
      :createDialog="createDialog"
      v-on:cancel="createDialog = false"
      v-on:createdPatient="goToPatient"
    />
  </section>
</template>

<script>
import router from "@/router";
import services from "@/services";
import moment from "moment";
import createPatient from "../patient/createPatient.vue"

export default {
  name: "patients-page",
  components: {
    createPatient
  },
  data: () => ({
    createDialog: false,
    loading: false,
    selected: {},
    searchText: "",
    timer: null,
    searchEnabled: false,
    statusLoader: false,
    selectedStatus: [],
    statusList: [],
    totalCount: 0,
    items: [],
    options: {
      groupBy: [],
      groupDesc: [],
      //itemsPerPage: 10,
      multiSort: true,
      mustSort: false,
      page: 1,
      sortBy: ["sessionStatusId"],
      sortDesc: [false],
    },
    headers: [
      {
        text: "Patient #",
        align: "start",
        sortable: true,
        value: "patientId",
      },
      {
        text: "Full Name",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      { text: "Setup Date", value: "setupDate", sortable: true },
      { text: "Disconnect Date", value: "disconnectDate", sortable: true },
      { text: "Patient Status", value: "sessionStatusId", sortable: true },
      {
        text: "Assigned Technician",
        value: "technicianFullName",
        sortable: false,
      },
    ],
    page: {
      title: "Patients",
      icon: "mdi-patient",
    },
    breadcrumbs: [
      {
        text: "Patients",
        disabled: true,
      },
    ],
    phoneMask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ") ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    defaultOptions: {
      groupBy: [],
      groupDesc: [],
      //itemsPerPage: 50000,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ["sessionStatusId"],
      sortDesc: [false],
    },
  }),
  watch: {
    options: {
      async handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          await this.getPatients();
        }, 500);
      },
      deep: true,
    },
    searchText: {
      async handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          await this.getPatients();
        }, 500);
      },
      deep: true,
    },
    selectedStatus: {
      async handler() {
        this.options = this.defaultOptions;
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          await this.getPatients();
        }, 500);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getSessionStatuses();
  },
    computed: {
        isAdminUser() {
      return this.$store.state.user.isAdmin;
    },
  },
  methods: {
    getPatients() {
      this.loading = true;

      let that = this;
      this.$store
        .dispatch("patient/getAvailablePatients", {
          options: that.options,
          searchText: that.searchText,
          statusList: that.selectedStatus,
        })
        .then((data) => {
          this.items = data.results;
          this.totalCount = data.totalCount;
        })
        .finally(() => (this.loading = false));
    },
      formatDate(date) {
        var year = new Date(date).getFullYear();
        if (year === 1 || year === '1') {
        return "- -"
          }

      return moment(date).format("MM/DD/YYYY");
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selected = item;
      router.push(`/patient/${item.patientId}`);
    },
    goToPatient(item){
        this.createDialog = false;
        router.push(`/patient/${item.patientId}`);
    },
    async getSessionStatuses() {
      this.statusLoader = true;

      return await services.patientService
        .getSessionStatuses()
        .then((response) => {
          if (Array.isArray(response)) {
            this.statusList = response;
            var defaultStatuses = response;
            this.selectedStatus = defaultStatuses;
          }
        })
        .finally(() => (this.statusLoader = false));
    },
    removeSelectedStatus(item) {
      this.selectedStatus.splice(this.selectedStatus.indexOf(item), 1);
      this.selectedStatus = [...this.selectedStatus];
    },
    normalizePhone(phone) {
      var defaultPhone = phone;
      //normalize string and remove all unnecessary characters
      phone = phone.replace(/[^\d]/g, "");

      //check if number length equals to 10
      if (phone.length == 10) {
        //reformat and return phone number
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }

      return defaultPhone;
    },
    getAlertColor(item) {
      if (!item) return "secondary";

      var status = item.description;

      switch (status) {
        case "Setup Pending":
          return "yellow";
        case "Setup Complete":
          return "yellow";
        case "Active":
          return "primary";
        case "Disconnect Pending":
          return "error";
        case "Disconnect Complete":
          return "error";
        case "Upload & Battery Change Pending":
          return "success";
        case "Upload & Battery Change Complete":
          return "success";
        default:
          return "secondary";
      }
    },
  },
};
</script>
